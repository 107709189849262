export const DATA = [
  {
    id: '1',
    name: 'Result Prediction',
    imgSrc: '/Images/projects/result.png',
    description:
      'Machine learning project that focuses on predicting student grades using ensemble regression models, specifically RandomForest and ExtraTrees. This project includes a dynamic web application built with Next.js and Tailwind CSS, allowing users to conveniently access and visualize grade predictions based on historical subject scores.',
    codeLink: 'https://github.com/prshnt-bhushal/ResultPredictionUsingML',
    childComponentTypes: [
      'nextIcon',
      'mongodbIcon',
      'flaskIcon',
      'sklearnIcon',
    ],
    projectDuration: '3 months',
    teamSize: '3',
    role: 'Software Engineer and Data Scientist',
  },
  {
    id: '2',
    name: 'IMS - Kirana',
    imgSrc: '/Images/projects/Ims-Kirana.jpg',
    description:
      'IMS Kirana is a modern, web-based Inventory Management System designed to simplify product tracking, streamline sales operations, and automate day-to-day store management. Built using React and Firebase, IMS Kirana offers a seamless user experience with real-time data synchronization, making it the ideal choice for small and medium-sized businesses looking for an efficient way to manage their inventory. With its integrated Point of Sale (POS) capabilities, IMS Kirana allows you to process transactions quickly, generate sales reports, and monitor stock levels with ease. Whether you are handling a small grocery store or a larger retail business, IMS Kirana is equipped with everything you need to keep your business operations running smoothly.',
    codeLink: 'https://github.com/prshnt-bhushal/ims-kirana',
    onlineLink: 'https://ims-kirana.vercel.app/',
    childComponentTypes: ['reactIcon', 'firebaseIcon', 'sassIcon'],
    projectDuration: '1 month and ongoing',
    teamSize: '1',
    role: 'Full stack Engineer',
  },
  {
    id: '3',
    name: 'TradeEx',
    imgSrc: '/Images/projects/tradeexlogo.png',
    description:
      'This web-based platform, built using React and Firebase, centers around the concept of facilitating goods bartering between users. It provides a user-friendly environment for individuals to engage in direct item exchanges, promoting community-driven and sustainable consumption',
    codeLink: 'https://github.com/prshnt-bhushal/tradeEx-v3',
    onlineLink: 'https://trade-and-exchange.vercel.app/',
    childComponentTypes: ['reactIcon', 'firebaseIcon'],
    projectDuration: '2 months',
    teamSize: '3',
    role: 'Full Stack Developer',
  },
  {
    id: '16',
    name: 'Route',
    imgSrc: '/Images/projects/route.png',
    description: `This Android app, developed using Java in Android Studio and integrated with Google's Map API, is designed to assist users in finding the most convenient bus services to reach their desired destinations. It simplifies the process of locating suitable transportation options for a hassle-free travel experience`,
    codeLink: 'https://github.com/prshnt-bhushal/Route',
    childComponentTypes: ['javaIcon', 'googleIcon'],
    projectDuration: '3 months',
    teamSize: '3',
    role: 'Mobile App Developer',
  },
  // {
  //   id: '4',
  //   name: 'Organizational Website',
  //   imgSrc: '/Images/projects/dailekhilogo.png',
  //   description:
  //     'This project includes a dynamic web application built with Next.js and Tailwind CSS, allowing users to conveniently access and visualize the organizational information.',
  //   codeLink: 'https://github.com/prshnt-bhushal/dailekhi-sewa-samajh',
  //   onlineLink: 'https://dailekhi-sewa-samajh.vercel.app/',
  //   childComponentTypes: ['nextIcon', 'tailwindIcon', 'mongodbIcon'],
  //   projectDuration: '2 months',
  //   teamSize: '1',
  //   role: 'Full Stack Developer',
  // },
  {
    id: '10',
    name: 'Kura',
    imgSrc: '/Images/projects/Kura.png',
    description: `This project includes web application built on React, allowing users to text on a boardcast channel, It also includes authentication system for users to login and get proper id to get started with chat. This project has the huge role to get user connected on My previous project TradeEx`,
    codeLink: 'https://github.com/prshnt-bhushal/kura',
    childComponentTypes: ['reactIcon', 'nodeIcon', 'firebaseIcon'],
    projectDuration: '1 month',
    teamSize: '1',
    role: 'Full Stack Developer',
  },
  {
    id: '5',
    name: 'Expenses Tracker',
    imgSrc: '/Images/projects/expenses.png',
    description: `Expenses Tracker is a web application that allows users to track their expenses. This project includes a dynamic web application built with React.js and Tailwind CSS, allowing users to conveniently access and visualize their expenses.`,
    codeLink: 'https://github.com/prshnt-bhushal/ExpenseTracker',
    onlineLink: 'https://expense-tracker-delta-puce.vercel.app/',
    childComponentTypes: ['reactIcon', 'tailwindIcon'],
    projectDuration: '1 and half month',
    teamSize: '1',
    role: 'Frontend Developer',
  },
  {
    id: '6',
    name: 'Personal Website',
    imgSrc: '/Images/profile/signature.png',
    description: `This personal website is built using React.js, Tailwind CSS. It is designed to showcase my projects, skills, and experiences.`,
    codeLink: 'https://github.com/prshnt-bhushal/portfolioReact',
    onlineLink: 'https://prashantbhushal.com.np/',
    childComponentTypes: ['reactIcon', 'tailwindIcon'],
    projectDuration: '2 months and ongoing',
    teamSize: '1',
    role: 'Owner and Developer',
  },
  {
    id: '7',
    name: 'Todo Web App',
    imgSrc: '/Images/projects/todoWebLogo.png',
    description: `This project includes web application built with pure React.js and CSS, allowing users to conveniently access and visualize their todo list.`,
    codeLink: 'https://github.com/prshnt-bhushal/reactTodo',
    childComponentTypes: ['reactIcon'],
    projectDuration: 'half month',
    teamSize: '1',
    role: 'Frontend Developer',
  },
  {
    id: '8',
    name: 'MERN Blog Web App',
    imgSrc: '/Images/projects/blogImage.png',
    description: `This project includes web application built with MERN stack, allowing users to write blogs and share it with the world. It also includes authentication system for users to login and write blogs.`,
    codeLink: 'https://github.com/prshnt-bhushal/blog-react',
    childComponentTypes: [
      'reactIcon',
      'nodeIcon',
      'mongodbIcon',
      'tailwindIcon',
    ],
    projectDuration: '1 month',
    teamSize: '1',
    role: 'Full Stack Developer',
  },
  {
    id: '9',
    name: 'React Admin Panel',
    imgSrc: '/Images/projects/dashboardImage.png',
    description: `This project includes web application built with MERN stack, allowing users to see Dashboard, it is use to know the basics of dashboards. It also includes authentication system for users to login.`,
    codeLink: 'https://github.com/prshnt-bhushal/react-admin-panel',
    childComponentTypes: ['reactIcon', 'nodeIcon', 'mongodbIcon'],
    projectDuration: '1 month',
    teamSize: '1',
    role: 'Full Stack Developer',
  },
];
